/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Subtitle, SeparateLine, Title, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Michelské pekárny Premium s.r.o."}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--style3 --full" style={{"backgroundColor":"rgba(170,53,53,1)","paddingBottom":1,"paddingLeft":0}} anim={""} name={"uvod"} animS={"5"} fullscreen={true}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"paddingLeft":0,"paddingRight":0,"marginBottom":0}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s5 --center" style={{"paddingBottom":107}} anim={"4"} animS={"5"}>
              
              <Image className="--shape3 mt--30" style={{"maxWidth":868,"paddingRight":0,"marginBottom":67,"paddingLeft":0}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/38877/ea115780474a460499c93aed19147f8b_s=350x_.png 350w, https://cdn.swbpg.com/t/38877/ea115780474a460499c93aed19147f8b_s=660x_.png 660w, https://cdn.swbpg.com/t/38877/ea115780474a460499c93aed19147f8b_s=860x_.png 860w, https://cdn.swbpg.com/t/38877/ea115780474a460499c93aed19147f8b_s=1400x_.png 1400w"} alt={""} src={"https://cdn.swbpg.com/t/38877/ea115780474a460499c93aed19147f8b_s=860x_.png"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--center fs--26" content={"<span style=\"color: var(--white);\">Michelské pekárny Premium s. r. o. jsou dceřinou společností společnosti Michelské pekárny a. s.,&nbsp; vlastníkem ochranné známky a&nbsp;</span><span style=\"color: var(--white);\">výhradním výrobcem&nbsp; populárních výrobků řady Metro dezert. V portfoliu dále nabízíme naše extrudované výrobky, jako jsou Křehké plátky a jiné tržní druhy (křupky, tyčinky), vyráběné také pod ochrannou známkou Dobré pečivo.</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"25shxrspggx"} layout={"l3"}>
        </SeparateLine>


        <Column style={{"paddingTop":2,"paddingBottom":6}} name={"informace"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box fs--62" content={"Naše produktové řady"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 mt--50" style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s3 --shadow2" style={{"maxWidth":480}} anim={"6"} animS={"3"}>
              
              <Image style={{"maxWidth":1080}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/4ce203b5f7954428ae3b4bfb1f5593af_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/38877/4ce203b5f7954428ae3b4bfb1f5593af_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38877/4ce203b5f7954428ae3b4bfb1f5593af_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38877/4ce203b5f7954428ae3b4bfb1f5593af_s=860x_.jpg 860w"}>
              </Image>

              <Title className="title-box" content={"<a href=\"/produkty#kp\">Křehké plátky</a>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":321}} content={"Křehké plátky jsou trvanlivé výrobky, vyráběné extruzí. Jsou moderním doplňkem běžné stravy, jako náhrada za běžné pečivo. Oblíbené jsou u všech, kteří pečují o své zdraví, jsou lehce stravitelné a s významným obsahem vlákniny. Díky svému složení obsahují řadu hodnotných živin. Je možné, díky jejich zařazení do jídelníčku, jednoduše omezovat množství spotřebovaného pečiva a zvolit si tak&nbsp; vhodnou alternativu k obvykle používanému bílému pšeničnému pečivu."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim6 --anim-s3 --shadow2" style={{"maxWidth":480}} anim={"6"} animS={"3"}>
              
              <Image style={{"maxWidth":399}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/897962ed08d348678525504b21f8d197_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/38877/897962ed08d348678525504b21f8d197_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38877/897962ed08d348678525504b21f8d197_s=660x_.jpg 660w"}>
              </Image>

              <Title className="title-box" content={"<a href=\"/produkty#krupky-tycky\">Křupky, Tyčinky</a>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":321}} content={"Extrudované výrobky,&nbsp;<br>speciální výrobky, ke kterým patří:&nbsp;<br>&nbsp;- Křehké kukuřičné tyčinky\n<br>- Křehké křupky kukuřičné\n<br>Výhodou je dlouhá trvanlivost, výborná chut´ a stálá kvalita při dodržení všech standardních podmínek kladených na jejich výrobu.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim6 --anim-s3 --shadow2" style={{"maxWidth":480}} anim={"6"} animS={"3"}>
              
              <Image style={{"maxWidth":351}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/921586b2349642fd8a8741c9998061a5_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/38877/921586b2349642fd8a8741c9998061a5_s=350x_.png 350w"} lightbox={false}>
              </Image>

              <Title className="title-box" content={"<a href=\"/produkty#metro\">Metro dezert</a>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":321}} content={"Jemné pečivo, piškot s tukovou náplní, fondán a tmavá poleva.<br>Balené po 4 nebo 6 kusech, s minimální trvanlivostí 90 dnů.&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"var(--color-supplementary)"}} name={"paticka"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" style={{"maxWidth":1420}} anim={"2"} animS={"2"} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box fs--10" style={{"maxWidth":366}} content={"Údaje o společnosti <br>Michelské pekárny a.s.\n<br>Michelské pekárny Premium s.r.o.\n<br><br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box fs--11" style={{"maxWidth":366}} content={"<span style=\"font-weight: bold;\">Michelské pekárny Premium s.r.o.\n</span><br>sídlo a kontaktní adresa společnosti:\n<br>U krčského nádraží 229/17, Krč, 140 00 Praha 4\n<br>zapsaná v obchodním rejstříku Městského soudu v Praze,\n<br>oddíl C, vložka 198709\n<br>IČ: 242 63 362 DIČ: CZ24263362\n<br>Výše základního kapitálu 200 000,- Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--left fs--11" style={{"maxWidth":473}} content={"<span style=\"font-weight: bold;\">Michelské pekárny a.s.\n</span><br>sídlo a kontaktní adresa společnosti: <br>U krčského nádraží 229/17, Krč, 140 00 Praha 4<br>zapsaná v obchodním rejstříku Městského soudu v Praze, <br>oddíl B, vložka 648\n<br>IČ: 161 92 583 DIČ: CZ16192583\n<br>Výše základního kapitálu 3 445 498,- Kč"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}